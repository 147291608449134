<template>
  <div class="point-detail-wrapper">
    <ul class="detail-table-header border-bottom-1px">
      <li v-for="header in tableHeaders" :key="header.key">
        {{header.name}}
      </li>
    </ul>
    <scroll-list
      :page="page"
      :pageSize="pageSize"
      :total="total"
      :data="data"
      :getData="getData">
      <ul class="detail-row" v-for="(item, index) in data" :key="item.id">
        <li
          class="detail-item van-ellipsis"
          v-for="header in tableHeaders"
          :key="header.key">{{item[header.key] | filterItem(header.key, data[index])}}</li>
      </ul>
    </scroll-list>
  </div>
</template>

<script>
import API from '@/api/index'
const { getCreditRecords } = API

export default {
  name: 'pointDetail',
  data () {
    return {
      tableHeaders: [
        {
          name: '奖品/事项',
          key: 'creditRuleName'
        },
        {
          name: '状态',
          key: 'state'
        },
        {
          name: '发生时间',
          key: 'createTime'
        },
        {
          name: '积分',
          key: 'creditAmount'
        }
      ],
      data: [], // 分页数据
      total: -1,
      page: 1,
      pageSize: 10
    }
  },
  filters: {
    filterItem: (val, key, data) => {
      if (key === 'state') {
        switch (val) {
          case 1: return '已申请'
          case 2: return '已兑换'
          case 3: return '已充值'
          case 4: return '已退回'
          default: return ''
        }
      } else if (key === 'creditAmount') {
        return data.creditType + val + '积分'
      } else {
        return val
      }
    }
  },
  methods: {
    // 获取积分明细列表
    async getData (isRefresh) {
      if (isRefresh) {
        this.page = 1
      }

      let res = await getCreditRecords({
        start: this.page,
        limit: this.pageSize
      })

      if (isRefresh || this.total === -1) {
        if (res.success) {
          this.total = res.totalCount
        } else {
          throw new Error(res.message)
        }
      }

      this.data = isRefresh ? res.data : this.data.concat(res.data)
      this.page++
    }
  },
  components: {
    'scroll-list': () => import('@/components/ScrollList.vue')
  }
}
</script>

<style lang="scss" scoped>
  .point-detail-wrapper{
    padding: 24px;
    ul{
      display: flex;
      &.detail-table-header{
        padding-bottom: 24px;
        margin-bottom: 24px;
        >li{
          font-size: 30px;
        }
      }
      >li{
        width: 130px;
        text-align: center;
        font-size: 26px;
        color: #333;
        &:first-child{
          width: 140px;
        }
        &:nth-child(3){
          width: 254px;
        }
      }
    }
    .detail-row{
      font-size: 26px;
      >li{
        color: #666;
        padding: 24px 0;
      }
    }
  }
</style>
